import React, { useEffect, useState } from 'react'
import { MdLocationPin } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';
import logo from '../assets/images/riya_drop_taxi.png';
import { RxHamburgerMenu } from 'react-icons/rx';
import { AiFillCloseCircle } from 'react-icons/ai';
import NavList from './NavList';
import { Link } from 'react-router-dom';
import { FaPhone } from "react-icons/fa";
import { IoMail } from "react-icons/io5";

import Contact from './Contact';
const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [openNav, setOpenNav] = useState(false)
    function openNavBat() {
        setOpenNav(true);
    }
    function closeNavBar() {
        setOpenNav(false);
    }

    useEffect(() => {
        // Define a function to handle scroll event
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Attach the event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Conditionally apply the class based on the state
    const headerClass = isScrolled ? 'header_parent header_scrolled' : 'header_parent';

    return (
        <div className={headerClass}>

            <div className='bg-black'>
               
                < div className={`flex py-5 justify-around`}>

                    <Link to="/">
                        < img src={logo} alt="logo" className='w-[70%] md:w-[30%] lg:w-[50%]' /></Link>
                    <div className='hidden font-medium  lg:flex md:items-center  '>
                        < NavList ul_class="flex gap-x-4 md:gap-x-6 lg:gap-x-5 xl:gap-x-8 lg:justify-between xl:justify-end text-lg sm:text-base  md:text-lg lg:text-xl text-black items-center nav_link_parent" li_class="text-white" />
                    </div>
                    <div className='hidden xl:flex xl:items-center  '>
                        <a href="#book_taxi_form" className='th-btn-reverse '>Book Now</a>
                    </div>
                    <div className='block lg:hidden cursor-pointer pr-8 text-white'>
                        <span onClick={openNavBat} className="p-[15px]"><RxHamburgerMenu className=' text-[24px] md:text-[28px] text-white' /></span>
                    </div>
                    <div className={`fixed bg-white h-full w-[60%] top-0 left-0 z-50 transition-all ease-linear ${openNav ? '' : '-translate-x-[110%]'}`}>
                        <div className='px-4 py-3 bg-[#a1a1a1]'>
                            <Link to="/">    <img src={logo} alt="logo" className='w-[300px] mx-auto px-5' /> </Link>
                        </div>
                        <div className='py-6 px-4'>
                            <NavList ul_class="font-semibold text-[14px] text-black flex flex-col gap-x-6 ml-6 uppercase gap-y-4" li_class="pt-3 border-b-2 pb-3" close_click={closeNavBar} />
                            <span onClick={closeNavBar} className='absolute top-4 right-0 cursor-pointer'><AiFillCloseCircle className='text-[25px] text-[#e30d16] ' /></span>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        
    )
}

export default Header