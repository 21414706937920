import React from 'react'

const Look = () => {
    return (
        <div className='container md:py-16 mt-4 px-6 md:px-14 -mb-[100px] relative z-[1]'>
            <div className=' mx-auto bg-[#ffbd59]  py-4 sm:py-6 md:py-8 lg:py-10 px-4 rounded-md'>
                <div className='flex flex-col items-center gap-x-5 gap-y-2 flex-wrap md:flex-nowrap text-white px-4 md:px-8 justify-center md:justify-normal'>
                    <h3 className='text-xl md:text-1xl lg:1xl font-bold'>Experience the convenience and reliability of Riya Drop taxifor all your travel needs in Tamil Nadu. Book your ride today and travel with confidence!</h3>
                    <a href="tel:+918056324867" className='th-btn-reverse look-btn mt-2'>CALL +91 8056324867 </a>
                </div>
            </div>
        </div>
    )
}

export default Look