import React from "react";
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
import banner from "../../assets/cars/carBackground1.jpg";
import serach from "../../assets/images/search.png";
import star from "../../assets/images/star.png";
import flag from "../../assets/images/flag.png";
import taxi from "../../assets/images/taxiicon.png";
import aeroplane from "../../assets/images/aeroplaneicon.png";
import round from "../../assets/images/roundIcon.png";
import tourPackage from "../../assets/images/tour-guide-icon.png"

import OurServiceComponent from "./OurServiceComponent";
import vectorOneWayTaxi from "../../assets/images/vectorCard1.jpg";
import airporttransfers from "../../assets/images/vectorCard2.jpg";
import outstation from "../../assets/images/VectorCard3.jpg"
import inter from "../../assets/images/vectorCard4.jpg"
const OurServices = () => {
  const divStyle = {
    backgroundImage: `url( ${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    backgroundColor:"none",
  };

  return (
    <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16" id="service">
      <h2 className="text-4xl font-bold text-center">OUR SERVICES</h2>
      <p className="mt-4 text-center text-lg">Our services are available across Tamil Nadu, including major cities like Chennai, Coimbatore, Trichy, and Madurai. We also extend our services to Pondicherry and Bangalore, making us your go-to option for long-distance travel.</p>
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 mt-5 sm:mt-6 md:mt-8 lg:mt-10">
        <OurServiceComponent image={vectorOneWayTaxi} heading="One-Way Taxi Services" desc="Travel to your destination without worrying about the return trip. Pay only for the distance you travel."/>
        <OurServiceComponent image={airporttransfers} heading="Airport Transfers" desc="Hassle-free and punctual airport pick-ups and drop-offs to and from major airports in Tamil Nadu, Pondicherry, and Bangalore."/>
        <OurServiceComponent image={inter} heading="Inter-City Rides" desc="Comfortable and affordable rides between cities. Whether it's for business or leisure, we ensure you reach your destination relaxed and on time."/>

        <OurServiceComponent image={outstation} heading="Outstation Travel" desc="Planning a weekend getaway or a long trip? Our outstation taxi service offers you a reliable ride across state borders."/>

         </div>
    </div>

  );
};

export default OurServices;
