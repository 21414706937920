import React from "react";
import banner from "../assets/images/footer-skyline.png";
import Form from "./Form";
import { FaPhone } from "react-icons/fa6";
import img1 from "../assets/images/ser1.png";
import img2 from "../assets/images/ser2.png";
import img3 from "../assets/images/ser3.png";
import img4 from "../assets/images/ser4.png";
import callicon from "../assets/images/callnow.gif"
import { Link } from 'react-router-dom';
import car1 from '../assets/images/truck-1.png';
import car2 from '../assets/images/truck-2.png';
import car3 from '../assets/images/truck-3.png'

const BookingForm = () => {
  const divStyle = {
    backgroundImage: `url( ${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    backgroundColor:"none",
    // animation :`( ${animate} ) 1s linear infinite`  ,  
    
  };

  return (
    <>
    <div className=" form_bg relative ">
      <div className="container  py-2 mx-auto  px-0 sm:px-4   md:px-8 md:pb-24 lg:px-16 lg:pb-24 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2  gap-y-6 gap-x-10 ">
          <div className="grid grid-cols-1">
          <div className="bg-white  rounded-lg px-4  pb-4 order-1  md:order-0 lg:order-0" id="book_taxi_form">

            <Form />
            
          </div>
          </div>
          {/* <div className="order-0 md:order-1 lg:order-1">
            <img src={banner}/>
            </div> */}
           <div className="w-full md:w-[90%] lg:mt-20 mx-auto md:ml-auto px-4 sm:px-0">
            {/* <p className=" text-white font-semibold text-lg">
              Book Drop Taxi Service with Riya Drop taxi
            </p> */}
                   <div className=" hidden md:block text-md text-white md:text-black">Riya Drop taxi</div>
                    <h3 className="hidden md:block  mt-4 font-bold text-white md:text-black text-3xl md:text-5xl  lg:text-5xl ">
                    Book One Way Taxi
                    { " "}
        </h3>

            {/* <div className=" text-6xl text-white font-bold mt-4">
              Welcome to
            </div>
            <div className=" text-6xl text-white font-bold mt-4">
            Riya Drop taxi

 
            </div> */}
            <div className="hidden md:block mt-4 text-lg  text-white md:text-black">
              {" "}
              We are providing one way taxi, outstation cabs, drop taxi and airport
          taxi service.
            </div>
            <div className=" text-white font-bold text-lg mt-10 ">
            <Link to="tel:+918056324867">  <img src={callicon} /> </Link>
            </div>
          </div> 
        </div>
      </div>
  <div className="running-car absolute">
<div className="car1"></div>
<div className="car2"></div>
<div className="car3"></div>
  </div>

    </div>
    
    </>

  );
};

export default BookingForm;
