import React from 'react'
import banner from "../../assets/images/section1_bg.jpg";
import serach from "../../assets/images/search.png";
import star from "../../assets/images/star.png";
import flag from "../../assets/images/flag.png";
import car from "../../assets/cars/carBackground.jpg"

const ChooseUs = () => {
  const divStyle = {
    backgroundImage: `url( ${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    backgroundColor: "none",
  };
  const divStyle2 = {
    backgroundImage: `url( ${car})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    backgroundColor:"none",
    // animation :`( ${animate} ) 1s linear infinite`  ,  
    
  };


  return (
    < >
      <div className="bg-work bg-fixed relative py-5 mx-auto px-4 md:px-8 lg:px-16" style={divStyle2}>
        <div className="container flex flex-col  relative z-[10] text-white justify-between gap-y-5">
          <h2 className=" text-3xl font-semibold text-center">See How It Work </h2>
          <p className=" text-center">We offers Outstation cabs and One way taxi at affordable and best price.</p>
          <div className="p-4 grid grid-cols-1 py-10 sm:grid-cols-1 md:grid-cols-3 md-py-28 lg:grid-cols-3  lg-py-28 gap-x-5 place-items-center">
            <div className=" grid grid-col-4 mb-5 md:mb-0 lg:mb-0 w-full h-full border-solid border-2 border-white bg-transparent color-white flex flex-col gap-y-3 p-3 rounded-lg">
              <div >
                <img src={serach} />
              </div>
              <div>Choose Your Location
              </div>
              <ol>
                <li>
                  1.Enter your pickup & Drop Location
                </li>
                <li>
                  2.Choose your Vehicle

                </li>
              </ol>
            </div>
            <div className=" grid grid-col-4 mb-5 md:mb-0 lg:mb-0 w-full h-full border-solid border-2 border-white bg-transparent color-white flex flex-col gap-y-3 p-3 rounded-lg">
              <div>
                <img src={star} />
              </div>
              <div>Fill your Details
              </div>
              <ol>
                <li>
                  1.Enter Your Name & Mobile
                </li>
                <li>
                  2.Select your Travel Date

                </li>
              </ol>
            </div>
            <div className=" grid grid-col-4 mb-5 md:mb-0 w-full h-full lg:mb-0 border-solid border-2 border-white bg-transparent color-white flex flex-col gap-y-3 p-3 rounded-lg ">
              <div>
                <img src={flag} />
              </div>
              <div>Booking Confirmation
              </div>
              <ol>
                <li>
                  Just relax, your booking confirmation will sent your mobile number.<br />
                  Our representative will call you shortly.

                </li>
              </ol>
            </div>

          </div>
        </div>
      </div>
      <div style={divStyle}>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16" >
        <div className="grid grid-cols-1 ">
          <div className="p-6">
            <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
              Why Choose Us?
            </h4>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">• Convenient Booking</h3>
              <p>
              Book your ride easily through our user-friendly platform. Whether you're planning a last-minute trip or scheduling in advance, we've got you covered.             
              </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">•	Affordable Pricing</h3>
              <p>
              Enjoy competitive rates without compromising on quality. We offer transparent pricing with no hidden charges, so you know exactly what you're paying for. </p>            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">•	Experienced Drivers: </h3>
              <p>
              Our drivers are well-trained and familiar with the routes, ensuring your journey is safe, smooth, and on time.
              </p>            
              </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">•	24/7 Availability</h3>
              <p>
              We are at your service round the clock. Whether it's an early morning airport drop or a late-night ride, Riyadrop Taxi is just a call away.
              </p>            </div>
          </div>
          {/* <div className="p-6">
            <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
              How It Works                </h4>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">Book Your Ride:</h3>
              <p>
                Choose your pickup and drop-off locations, select your vehicle, and book your ride online.              </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">Get Confirmation:</h3>
              <p>
                Receive instant confirmation of your booking with driver details.            </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">Enjoy Your Journey:</h3>
              <p>
                Sit back, relax, and enjoy a smooth ride to your destination.                    </p>
            </div>
            <div className="flex flex-col gap-y-[10px] pt-4 mt-4">
              <h3 className="font-semibold text-2xl">Pay Easily:</h3>
              <p>
                Choose from multiple payment options for a hassle-free experience.              </p>
            </div>
          </div> */}
        </div>
        

      </div>
      </div>
    </>

  )
}

export default ChooseUs
