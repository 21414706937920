import React from 'react'

import { FaCheck } from 'react-icons/fa';
import { FaPhone } from "react-icons/fa6";
import airport from "../assets/images/airport transfer-covaicalltaxi.jpg";
import time from "../assets/images/24X7taxiservice.jpg";
import localrentals from "../assets/images/riya_drop_taxi.png";
import outstation from "../assets/images/outstation trips-covaicalltaxi.jpg"

const About = () => {
    return (
        <div id='about'>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-14  items-center'>
                    <div className=' '>
                        < img src={localrentals} alt="about" className="" />
                     
                    </div>
                    <div className='mt-20 md:mt-0  w-full '>
                        <h4 className=' text-lg font-medium primary-color'>About Us</h4>
                        <div className='text-1xl md:text-3xl font-medium mt-4'>
                        Welcome to Riyadrop Taxi
                        </div>
                        <div className='text-md md:text-lg font-semibold mt-4 opacity-80'>
                        Your Trusted Drop Taxi Service Across Tamil Nadu, Pondicherry, and Bangalore
                        </div>

                        <div className="mt-2">
                            <p>
                            At Riyadrop Taxi, we are dedicated to providing reliable and comfortable drop taxi services for your travel needs. Based in Madurai, we cater to customers across Tamil Nadu, Pondicherry, and Bangalore, ensuring a seamless travel experience every time you choose us.                                </p>
                            <span className='flex gap-x-2  mt-3'> <FaCheck size={17} className='opacity-50' /> 24/7 Support</span>
                            <span className='flex gap-x-2  mt-3'> <FaCheck size={17} className='opacity-50' /> Best Price</span>
                            <span className='flex gap-x-2  mt-3'> <FaCheck size={17} className='opacity-50' /> Experienced Drivers</span>

                            {/* <h3 className='text-1xl mt-4 font-medium'> ONE WAY TAXI</h3> */}
                            {/* <p className='mt-1 pl-4'>No Return fare! Why need to pay more for one way Taxi, We're the most trusted one.

                            </p>
                        </div>
                        <div className="mt-4">
                            <h3 className='text-1xl mt-4 font-medium'> ROUND TRIP
                            </h3>
                            <p className='mt-1 pl-4'>Your pick-up address can be anywhere in pick-up city and drop address can be.
                            </p>
                        </div>
                        <div className="mt-4">
                            <h3 className='text-1xl mt-4 font-medium'> AIRPORT PICKUP
                            </h3>
                            <p className='mt-1 pl-4'>Be it welcoming your friend at the airport right on time or any emergency situation.
                            </p>
                        </div>
                        <div className="mt-4">
                            <h3 className='text-1xl mt-4 font-medium'> OUTSTATION CAB
                            </h3>
                            <p className='mt-1 pl-4'>Our Outstation taxi are the best for short and long trips to spend quality time with.
                            </p>
                        </div>
                        <div className="mt-4">
                            <h3 className='text-1xl mt-4 font-medium'> DROP TAXI
                            </h3>
                            <p className='mt-1 pl-4'>Book a Oneway drop taxi to take you to your destination in the town, anywhere, anytime.
                            </p>
                        </div>
                        <div className="mt-4">
                            <h3 className='text-1xl mt-4 font-medium'> 100% SAFETY
                            </h3>
                            <p className='mt-1 pl-4'>Verified drivers, an emergency alert button, and live ride tracking
                            </p> */}
                        </div>
                        {/* <p className='text-black opacity-60 md:text-lg mt-5'>We're in this business Since 2018 and we provide the best services
               </p> */}
                        {/* <ul className='about-ul'>
                            <li className='about-li'>Easy Taxi booking in minutes</li>
                            <li className='about-li'>The best and transparent Pricing in the Market</li>
                            <li className='about-li'>Experienced skilled Drivers.

                            </li>

                        </ul> */}
                        <div className='flex  mt-5 gap-x-2 '>
                            <div>
                                <a href="#book_taxi_form" className='th-btn'>Book Now</a>
                            </div>
                            <div>
                                <a href="tel:+918056324867 " className='th-btn-reverse'>
                                    <span className='flex gap-x-2 items-center '><FaPhone size={17} /> +91 8056324867</span>
                                </a>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default About